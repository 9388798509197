import React, { useEffect, useState } from "react";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";

import { restrictToParentElement } from "@dnd-kit/modifiers";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSwappingStrategy,
  arraySwap,
} from "@dnd-kit/sortable";
import Item from "./Item";

const Area = ({ positions, data, onSwap }) => {
  const [list, setList] = useState(data);
  const [grid, setGrid] = useState(positions);
  const [activeId, setActiveId] = useState(null);
  const [disableAll] = useState(false);

  useEffect(() => {
    setList(data);
    setGrid(positions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleOnOver(event) {}

  function handleDragStart(event) {
    let active = list.find((s) => s.id === event.active.id);
    setActiveId(active);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    setActiveId(null);
    if (over != null && active.id !== over.id) {
      setList((items) => {
        const oldIndex = items.findIndex((f) => f.id === active.id);
        const newIndex = items.findIndex((f) => f.id === over.id);
        return arraySwap(items, oldIndex, newIndex);
      });
      onSwap(event);
    }
  }

  function handleCancle(event) {}

  return (
    <div className="custom-drag-drop">
      <DndContext
        onDragCancel={handleCancle}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleOnOver}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={list} strategy={rectSwappingStrategy}>
          <DragOverlay>
            {activeId ? (
              <div className="custom-item">{activeId.letter}</div>
            ) : null}
          </DragOverlay>
          {list.map((item, index) => {
            if (item.isCorrect && !item.disabled) {
              return (
                <div className="custom-item-complete" key={item.id}>
                  {item.letter}
                </div>
              );
            } else {
              return (
                <Item
                  item={item}
                  key={item.id}
                  pos={grid[index]}
                  disable={disableAll}
                ></Item>
              );
            }
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default Area;
