import React from "react";

import { useSpring, animated, config } from "react-spring";

export default function GameOver({ title, subtitle, text }) {
  const props = useSpring({
    to: {
      x: -1100,
    },
    from: {
      x: 0,
    },
    reset: true,
    reverse: true,
    config: config.wobbly,
  });

  return (
    <animated.div style={props}>
      <div className="gameOver">
        <div className="card">
          <div className="cardTitle"> {title} </div>{" "}
          <div className="cardSubtitle"> {subtitle} </div>{" "}
          <div className="cardText"> {text} </div>{" "}
        </div>{" "}
      </div>{" "}
    </animated.div>
  );
}
