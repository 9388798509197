import React, { useState, useEffect } from "react";
import Area from "./Area";
import { v4 as uuidv4 } from "uuid";

const Board = ({ size, data, onSwap, onFinished }) => {
  const [grid, setGrid] = useState([]);
  const [letters, setLetters] = useState([]);

  const generateGrid = (size) => {
    var list = [];
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        list.push({ x: x, y: y });
      }
    }
    return list;
  };

  const generateData = (inGrid, inData) => {
    var list = [];
    inGrid.forEach((pos) => {
      var item = inData.find((i) => i.x === pos.x && i.y === pos.y);
      if (item) {
        list.push(item);
      } else {
        list.push({ id: uuidv4(), disabled: true });
      }
    });
    checkPositions(list);
    return list;
  };

  const checkPositions = (list) => {
    for (let i = 0; i < list.length; i++) {
      let originalLetter = list.find(
        (s) => s.orgX === list[i].x && s.orgY === list[i].y
      );
      if (
        (list[i].letter === originalLetter.letter && !list[i].isFailed) ||
        list[i].isCorrect
      ) {
        list[i].isCorrect = true;
      } else {
        list[i].isCorrect = false;
      }
    }
  };

  const swapPositions = (e) => {
    let first = e.active.id;
    let second = e.over.id;

    let lettersCopy = [...letters];

    let firstTile = lettersCopy.find((i) => i.id === first);
    let secondTile = lettersCopy.find((i) => i.id === second);

    let tempPos = firstTile.x;
    firstTile.x = secondTile.x;
    secondTile.x = tempPos;

    tempPos = firstTile.y;
    firstTile.y = secondTile.y;
    secondTile.y = tempPos;

    let before = countCorrectLetters(lettersCopy);
    checkPositions(lettersCopy);
    let after = countCorrectLetters(lettersCopy);
    setLetters(lettersCopy);
    let correctSwap = after - before;

    onSwap(lettersCopy, correctSwap);
    if (countCorrectLetters(lettersCopy) === lettersCopy.length) {
      onFinished();
    }
  };

  const countCorrectLetters = (list) => {
    return list.filter((l) => l.isCorrect).length;
  };

  useEffect(() => {
    let generatedGrid = generateGrid(size);
    let generatedData = generateData(generatedGrid, data);
    setGrid(generatedGrid);
    setLetters(generatedData);
    if (countCorrectLetters(generatedData) === generatedData.length) {
      onFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <Area positions={grid} data={letters} onSwap={swapPositions}></Area>;
};

export default Board;
