import React from "react";
import { useSpring, animated, config } from "react-spring";

export default function Modal({ show, onClose, children }) {
  const props = useSpring({
    to: { x: 0 },
    from: { x: 1000 },
    reset: true,
    reverse: false,
    config: config.stiff,
  });

  if (!show) {
    return null;
  } else {
    return (
      <div className="modal" id="modal" onClick={onClose}>
        <animated.div style={props}>
          <div className="modal-content">{children}</div>
        </animated.div>
        <div className="actions"></div>
      </div>
    );
  }
}
