import React from "react";

import { useSpring, animated, config } from "react-spring";

export default function TurnDisplay({ text }) {
  const props = useSpring({
    to: { fontSize: 50 },
    from: { fontSize: 40 },
    reset: true,
    reverse: true,
    config: config.stiff,
  });

  return (
    <div className="turnTextBox">
      <animated.div style={props} className="turn">
        {text}
      </animated.div>
    </div>
  );
}
