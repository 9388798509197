import React from "react";

import { useSpring, animated, config } from "react-spring";

export default function Statistics({
  title,
  text1,
  text2,
  text3,
  text4,
  text5,
}) {
  const props = useSpring({
    to: { x: -1100 },
    from: { x: 0 },
    reset: true,
    reverse: true,
    config: config.stiff,
  });

  return (
    <animated.div style={props}>
      <div className="gameOver">
        <div className="card">
          <div className="cardTitle">{title}</div>
          <div className="cardSubtitle">{text1}</div>
          <div className="cardSubtitle">{text2}</div>
          <div className="cardSubtitle">{text3}</div>
          <div className="cardSubtitle">{text4}</div>
          <div className="cardSubtitle">{text5}</div>
        </div>
      </div>
    </animated.div>
  );
}
