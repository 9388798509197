import React, { useEffect, useState } from "react";
import TurnDisplay from "../Components/TurnDisplay";
import Board from "../DragAndDrop/Board";
import GameOver from "../Components/GameOver";
import Statistics from "../Components/Statistics";
import Modal from "../Components/Modal";
import Help from "../Components/Help";

export default function GamePage() {
  const [turn, setTurn] = useState(10);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lost, setLost] = useState(false);
  const [won, setWon] = useState(false);
  const [stats, setStats] = useState(null);
  const [showHelp, setShowHelp] = useState(false);

  const onCloseHelp = () => {
    setShowHelp(!showHelp);
  };

  const getHourLeft = () => {
    let hour = new Date().getUTCHours();
    let hourLeft = 23 - hour;
    return hourLeft;
  };

  const getMinLeft = () => {
    let min = new Date().getMinutes();
    let minLeft = 60 - min;
    return minLeft;
  };

  const onswap = (state, correctSwap) => {
    let currentState = [...state];
    for (let i = 0; i < currentState.length; i++) {
      if (currentState[i].disabled) {
        currentState.splice(i, 1);
      }
    }

    if (correctSwap === 0) {
      correctSwap = -1;
    }
    let nextTurn = turn + correctSwap;
    setTurn(nextTurn);
    let date = JSON.parse(localStorage.getItem("dailyCrossState")).date;
    let save = { state: currentState, turn: nextTurn, date: date };
    localStorage.setItem("dailyCrossState", JSON.stringify(save));

    if (nextTurn === 0) {
      setLost(true);
      lockAll(currentState);
      setData(currentState);
      updateStreakSave(false);
      localStorage.setItem("dailyCrossAlreadyFinished", true);
    }
  };

  const onFinished = () => {
    setWon(true);
    updateStreakSave(true);
    localStorage.setItem("dailyCrossAlreadyFinished", true);
  };

  const updateStreakSave = (win) => {
    let save = JSON.parse(localStorage.getItem("dailyCrossSave"));
    let latestTurn = JSON.parse(localStorage.getItem("dailyCrossState")).turn;
    let alreadyFinished = localStorage.getItem("dailyCrossAlreadyFinished");

    if (save) {
      if (!alreadyFinished) {
        if (!save.highScore) {
          save.highScore = 0;
        }
        let score = latestTurn >= save.highScore ? latestTurn : save.highScore;
        if (win) {
          let newWinStreak = (save.winStreak = save.winStreak + 1);
          save = {
            winStreak: newWinStreak,
            highestStreak:
              newWinStreak >= save.highestStreak
                ? newWinStreak
                : save.highestStreak,
            played: save.played + 1,
            wins: save.wins + 1,
            highScore: score,
          };
        } else {
          save = {
            winStreak: 0,
            highestStreak: save.highestStreak,
            played: save.played + 1,
            wins: save.wins,
            highScore: score,
          };
        }
      }
    } else {
      if (win) {
        save = {
          winStreak: 1,
          highestStreak: 1,
          played: 1,
          wins: 1,
          highScore: latestTurn,
        };
      } else {
        save = {
          winStreak: 0,
          highestStreak: 0,
          played: 1,
          wins: 0,
          highScore: latestTurn,
        };
      }
    }
    localStorage.setItem("dailyCrossSave", JSON.stringify(save));
    setStats(save);
  };

  const lockAll = (list) => {
    let savedCorrect = [];
    for (let i = 0; i < list.length; i++) {
      list[i].locked = true;
      list[i].isFailed = true;
      if (list[i].isCorrect) {
        savedCorrect.push({ x: list[i].x, y: list[i].y });
      }
      list[i].x = list[i].orgX;
      list[i].y = list[i].orgY;
      list[i].isCorrect = false;
    }

    for (let i = 0; i < savedCorrect.length; i++) {
      list.find(
        (s) => s.x === savedCorrect[i].x && s.y === savedCorrect[i].y
      ).isCorrect = true;
    }
  };

  const getToday = () => {
    let today = new Date();
    let yyyy = today.getUTCFullYear();
    let mm = today.getUTCMonth() + 1; // Months start at 0!
    let dd = today.getUTCDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  async function fetchData() {
    try {
      const response = await fetch("/.netlify/functions/getPuzzle");
      const json = await response.json();
      const data = Object.values(json.data);
      setData(data[0].data);
      let save = { state: data[0].data, turn: 10, date: data[0].date };
      localStorage.setItem("dailyCrossState", JSON.stringify(save));
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    let save = JSON.parse(localStorage.getItem("dailyCrossState"));
    if (save && save.date === getToday()) {
      setData(save.state);
      setTurn(save.turn);
      if (save.turn === 0) {
        setLost(true);
        lockAll(save.state);
        updateStreakSave(false);
      }
      setIsLoading(false);
    } else {
      localStorage.removeItem("dailyCrossState");
      localStorage.removeItem("dailyCrossAlreadyFinished");
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="loading">
        <div className="loadingBox">
          {isLoading && <div className="lds-dual-ring"></div>}
        </div>
      </div>
      <div className="parentWrapper">
        <div className="parent">
          <div className="title-box">
            <div className="title">{getToday()}</div>
            <div className="helpButton" onClick={onCloseHelp}>
              Hjälp
            </div>
          </div>
          <div>
            {lost && (
              <GameOver
                title={"Tyvärr"}
                subtitle={"Du förlorade dagens kryss."}
                text={
                  "Nästa kryss släpps om " +
                  getHourLeft() +
                  " timmar och " +
                  getMinLeft() +
                  " minuter."
                }
              ></GameOver>
            )}
            {won && (
              <GameOver
                title={"Grattis"}
                subtitle={"Du vann dagens kryss med " + turn + " poäng."}
                text={
                  "Nästa kryss släpps om " +
                  getHourLeft() +
                  " timmar och " +
                  getMinLeft() +
                  " minuter."
                }
              ></GameOver>
            )}
          </div>
          <div>
            {stats && (
              <Statistics
                title={"Statistik"}
                text1={`Vinster i följd: ${stats.winStreak}`}
                text2={`Max vinster i följd: ${stats.highestStreak}`}
                text3={`Kryss spelade: ${stats.played}`}
                text4={`Vinster totalt: ${stats.wins}`}
                text5={`Högsta poäng: ${stats.highScore}`}
              ></Statistics>
            )}
          </div>
          <Modal show={showHelp} onClose={onCloseHelp}>
            <Help/>
          </Modal>

          <div className="boardContainer">
            {data && (
              <Board
                size={5}
                data={data}
                onSwap={onswap}
                onFinished={onFinished}
              ></Board>
            )}
          </div>
          <div className="turnContainer">
            {data && !won && !lost && <TurnDisplay text={turn}></TurnDisplay>}
          </div>
        </div>
      </div>
    </div>
  );
}
