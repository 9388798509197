import './App.css';
import GamePage from './Pages/GamePage';

function App() {
  return (
    <GamePage></GamePage>
  );
}

export default App;
