import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

export default function Item({ item, pos }) {
  function animateLayoutChanges(args) {
    const { isSorting } = args;
    if (!isSorting) {
      return false;
    }
    return true;
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    animateLayoutChanges,
    id: item.id,
    disabled: item.locked,
    transition: {
      duration: 200, // milliseconds
      easing: "ease-in-out",
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.6 : 1,
    zIndex: isDragging ? "100" : "auto",
    backgroundColor: item.isCorrect ? "green" : "white",
  };

  if (item.isFailed) {
    return <div className="custom-item-bg-failed">{item.letter}</div>;
  } else {
    return !item.disabled ? (
      <div
        className={"custom-item " + (item.isloading ? "lds-dual-ring" : "")}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {item.letter}{" "}
      </div>
    ) : (
      <div className="custom-item-bg"></div>
    );
  }
}
