import React from "react";

export default function Help() {
  return (
    <div>
      <div className="help-div">
        <div className="help-text">
          {" "}
          {
            "Flytta brickorna för att bilda rätt ord. Grön bricka indikerar att bokstaven är på rätt position."
          }{" "}
        </div>{" "}
        <div className="help-grid">
          <div className="custom-help-item-1"> i </div>{" "}
          <div className="custom-help-item-2"> b </div>{" "}
          <div className="custom-help-item-3"> l </div>{" "}
        </div>{" "}
        <br />
        <div className="help-text">
          {" "}
          {
            "Rätt position ger ett poäng. Flyttas brickan till fel position förloras ett poäng."
          }{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
